.containerBusca {
    display: flex;
    height: 100%;
}
.containerIconBusca {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    margin: 0 5px;
}

.containerInPaper {
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
    position: relative;
}
.iconPlatformPaper {
    position: absolute;
    font-size: 20px;
    color: #999;
    z-index: 0;
}

.containerHeaderPaper {
    display: flex;
    flex-direction: column;
    z-index: 2;
}
.containerHeaderPaper span {
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.containerHeaderPaper span:first-of-type {
    padding: 0 12px;
}
.containerHeaderPaper span:last-of-type {
    font-weight: normal;
}
.containerHeaderPaper:hover {
    color: #a71a1a;
}

.containerFooterPaper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.containerFooterPaper div:first-of-type {
    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
    flex-direction: row;
}
.containerFooterPaper div div {
    color: #fff;
    padding: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

}
.containerFooterPaper div div:first-of-type {
    border-radius: 4px 0 0 4px;
}
.containerFooterPaper div div:last-of-type {
    border-radius: 0 4px 4px 0;
}

.listXml {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #ccc;
}
.listXml div:first-of-type {
    display: flex;
    align-items: center;
}