.containerCalendar {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.rowCalendar {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.calendarBox {
    width: 100%;
    display: flex;
    border: 1px solid #bbb;
    justify-content: flex-end;
    padding: 3px;
}
.boxCell {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    cursor: pointer;
}
.boxCell:hover {
    background-color: #eee;
}

.isHand {
    background-color: #248fb2;
    color: #fff;
    width: 100%;
    text-align: right;
}
.isPag {
    background-color: #6bbd5b;
    color: #fff;
    width: 100%;
    text-align: right;
}
.isLoad {
    background-color: #000;
    color: #fff;
    width: 100%;
    text-align: right;
}

@media screen and (max-width: 545px) {
    .mediaCalendar {
        width: 100%;
        overflow-x: auto;
    }
    .containerCalendar {
      width: 600px;
    }
  }