.containerBoxHome {
    display: flex;
    flex-direction: column;
    color: #fff;
}
.containerBoxHome div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerBoxHome div:first-of-type {
    background-color: #a71a1a;
    height: 100px;
    font-size: 40px;
}
.containerBoxHome div:last-of-type {
    background-color: #a6a6a6;
    height: 30px;
}