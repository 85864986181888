input[type="month"] {
    position: relative;
}

input[type="month"]:after {
    content: "\25BC"; 
    color: #555;
    padding: 0 5px;
}


input[type="month"]:hover:after {
    color: #bf1400;
}
input[type="month"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}


input[type="month"]::-webkit-inner-spin-button {
    z-index: 1;
}


 input[type="month"]::-webkit-clear-button {
     z-index: 1;
 }