.info {
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.body-info {
    font-size: 15px;
}

.favorecido {
    font-size: 17px;
}

.loading {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}